import { createRouter, createWebHistory } from 'vue-router'
//layout
import OuterLayout from '@/layout/OuterLayout.vue';
import InnerLayout from '@/layout/InnerLayout.vue';
//页面
import RegisterPage from '@/views/RegisterPage.vue';
import HomePage from '@/views/HomePage.vue';
import HotelIndex from '@/views/HotelIndex.vue';
import HotelList from '@/views/HotelList.vue';
import HotelDetail from '@/views/HotelDetail.vue';

import HotelOrderBooking from '@/views/HotelOrderBooking.vue';
import HotelOrderPayment from '@/views/HotelOrderPayment.vue';


import OrderWechatPay from '@/views/OrderWechatPay.vue';
import OrderPaySuccess from '@/views/OrderPaySuccess.vue';
import OrderList from '@/views/OrderList.vue';
import OrderDetail from '@/views/OrderDetail.vue';



//注册cookie
import Cookies from 'js-cookie';

const routes = [

  {
    path: '/',
    component: OuterLayout,//绑定未登录头尾部框架
    children: [
      {
        path: '',
        name: 'HomePage',
        component: HomePage,
        meta: { title: '主页 - 春秋文旅-高端酒店预定' }
      },
      {
        path: 'reg',
        name: 'RegisterPage',
        component: RegisterPage,
        meta: { title: '主页 - 春秋文旅-申请注册' }
      },
      // 你可以在这里添加更多的子路由
    ],
  },


  {
    path: '/',
    component: InnerLayout,//绑定登录后头尾部框架
    children: [
      {
        path: 'hotels',
        name: 'HotelIndex',
        component: HotelIndex,
        meta: { title: '酒店搜索 - 春秋文旅-高端酒店预定' }
      },
      {
        path: 'hotel/list',
        name: 'HotelList',
        component: HotelList,
        meta: { title: '酒店搜索 - 酒店列表' }
      },
      {
        path: 'hotel/detail',
        name: 'HotelDetail',
        component: HotelDetail,
        meta: { title: '酒店搜索 - 酒店详细' }
      },
      {
        path: 'hotel/Order/Booking',
        name: 'HotelOrderBooking',
        component: HotelOrderBooking,
        meta: { title: '酒店预定 - 春秋文旅-酒店预定' }
      },
      {
        path: 'hotel/Order/Payment',
        name: 'HotelOrderPayment',
        component: HotelOrderPayment,
        meta: { title: '酒店预定 - 春秋文旅-酒店预定' }
      }
      // 你可以在这里添加更多的子路由
    ],
  },


  {
    path: '/order/',
    component: InnerLayout,//绑定登录后头尾部框架
    children: [

      {
        path: 'list',
        name: 'OrderList',
        component: OrderList,
        meta: { title: '酒店订单-订单管理-春秋文旅' }
      },
      {
        path: 'detail',
        name: 'OrderDetail',
        component: OrderDetail,
        meta: { title: '订单详细页-订单管理-春秋文旅' }
      },
      {
        path: 'wechatpay',
        name: 'OrderWechatPay',
        component: OrderWechatPay,
        meta: { title: '订单支付 - 微信支付' }
      },
      {
        path: 'paysuccess',
        name: 'OrderPaySuccess',
        component: OrderPaySuccess,
        meta: { title: '订单支付 - 支付完成' }
      }
      // 你可以在这里添加更多的子路由
    ],
  },




]



const router = createRouter({
  history: createWebHistory(),
  routes
})


router.beforeEach((to, from, next) => {
  const token = Cookies.get('token');
 // console.log(!token)
  //console.log(to)
  //非首页登录页面和未登录页面，则跳转到首页登录页面
  if (to.matched.some(record => record.path!="/") && !token) {
    next('/');
  } else {
    next();
  }
});


export default router
 
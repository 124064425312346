<template>
    <div>
      <a-row>
  
          <a-col :span="16">
            <div class="main-image-container" @click="showPreview(0)">
              <img :src="mainImage.ImagePath" class="main-image"  />
              <div class="overlay">
                <span>查看所有{{ images.length }}张照片</span>
              </div>
            </div>
          </a-col>
          <a-col :span="8">
            <a-row>
              <a-col v-for="(image, index) in images.slice(1, 5)" :key="index" :span="12">
                <div class="thumbnail-container" @click="showPreview(index + 1)">
                  <img :src="image.ImagePath" class="thumbnail"  />
                </div>
              </a-col>
            </a-row>
          </a-col>
      
      </a-row>
      <a-modal v-model:open="previewVisible" width="50%" :footer="null" >
      <div class="nd-flex space-between">
  
          <a-carousel ref="carousel" :autoplay="false" v-model:currentIndex="currentImageIndex" :dots="false" style="width:69%">
            <div v-for="(image, index) in images" :key="index" class="carousel-image-container">
              <img :src="image.ImagePath" class="carousel-image"  />
            </div>
          </a-carousel>
          <div class="thumbnail-preview" >
  
            <a-space :size="[8, 16]" wrap>
              <div v-for="(image, index) in images" :key="index" class="thumbnail-preview-container" @click="goToImage(index)">
              <img :src="image.ImagePath" class="thumbnail-preview-image" :class="{ active: index === currentImageIndex }"/>
            </div>
            </a-space>
  
          </div>
  
  
      </div>
  
  
  
  
      </a-modal>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      hotelCode: {
      type: Number,
      required: true
    }},
    data() {
      return {
        images:[],
        mainImage:{},
        previewVisible: false,
        currentImageIndex: 0
      };
    },
    created(){ 
          
      this.fetchHotelImages()
           
      
      console.log(this.images)
    },
    mounted() {

    },
    methods: {


      async fetchHotelImages() {       
       try {   
         const response = await this.$axios.get('/hotelImage/'+this.hotelCode);
            this.images=response.data.HotelImage
            this.mainImage=response.data.HotelImage.filter(m=>m.ImageType=='main')[0]
         console.log(this.mainImage)

       } catch (error) {
         console.error('获取数据失败:', error);
       }
     },


      showPreview(index) {
        this.currentImageIndex = index;
        this.previewVisible = true;
        this.$nextTick(() => {
          this.$refs.carousel.goTo(index);
        });
      },
      goToImage(index) {
        this.currentImageIndex = index;
        this.$refs.carousel.goTo(index);
      },
    },
  };
  </script>
  
  <style scoped>
  .main-image-container {
    position: relative;
  }
  .overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  text-align: center;
  padding: 10px;
  cursor: pointer;
}
  .main-image {
    width: 100%;
    height: auto;
  }
  
  .thumbnail-container {
    padding: 5px;
  }
  .thumbnail {
    width: 100%;
    height: auto;
  }
  .carousel-image-container {
    text-align: center;
  }
  .carousel-image {
  
    width: 100%;
    height: 500px;
  }
  .thumbnail-preview {
  
    justify-content: center;
    overflow-y: auto;
    height: 500px;
  
    margin-left: 10px;
  }
  .thumbnail-preview-container {
    margin: 0 5px;
    cursor: pointer;
  }
  .thumbnail-preview-image {
    width: 100px;
    height: auto;
    opacity: 0.6;
  }
  .thumbnail-preview-image.active {
    border: 2px solid #1890ff;
    opacity: 1;
  }
  </style>
  
<template>

  <div class="search-box" ref="searchBoxContainer">
      <div class="search-box-item">
          <label>目的地/酒店名称</label>
          <div style="width: 350px;"> <KeywordSuggession :propsKeyword="keywordValue" @selectKeyword="handleSelectKeyword" ref="keywordSuggessionComponent"/></div>
      </div>
      <div class="search-box-item">
          <label><span  class="checkin-date">入住日期</span><span>离店日期</span></label>
          <div style="width: 400px;">
          
            <a-range-picker 
     
              :allowClear="false"
              suffixIcon=""
              :disabledDate="disabledDate"
              :value="checkDates"
              format="YYYY-MM-DD(ddd)"
              :open="openDatePicker"
              @change="onDatesChange"
              @openChange="onDatesOpenChange"
              @calendarChange="onCalendarChange"
            >
              <template v-slot:separator>
                <span class="night">{{ nights }}晚</span>
              </template>
            </a-range-picker>
          
          </div>
          
      </div>

      <div class="search-box-item">
        <label>房间数，入住总人数</label>
        <div><OccupancySelect :propsOccupancy="occupancy" :propsOpen="openOccupancySelect" @occupancySelect="selectOccupancy"   @occupancyOpen="occupancyOpen"  ref="occupancySelectComponent" /></div>
      </div>


      <div class="search-box-item"  v-if="(type=='detail')">
        <a-button type="primary"   @click="searchHotelList" @click.stop>查询</a-button>
      </div>
      <div class="search-box-item"  v-else>
        <a-button type="primary" size="large"  @click="searchHotelList" @click.stop>查询</a-button>
      </div>
  </div>     
</template>

<script>
import router from '@/assets/router';
import KeywordSuggession from '@/components/keywordSuggession.vue';
import OccupancySelect from '@/components/occupancySelect.vue';

import dayjs from 'dayjs';


export default {
  props: {
      type: {
        type: String,
        default:""
      },
      propsParams: {
        type: Object
      },
      propsKeyword: {
        type: Object
      }
  },
  data() {
    return {
      openDatePicker:false,
      openOccupancySelect:false,
      changeKeywordValue:false,
      changeDateValue:false,
      changeOccupancy:false,
      intermediateRange: null,
      checkDates: [dayjs().add(1, 'day'), dayjs().add(2, 'day')],
      keywordValue:null,
      occupancy:{},
      nights: 1

    };
  },
  components: {
    KeywordSuggession,
    OccupancySelect
  },
  watch: {
    
    keywordValue(newVal,oldVal) {
      //console.log('Keyword Value :', oldVal);
     //console.log('Keyword Value updated:', newVal);
      if(JSON.stringify(newVal)!=JSON.stringify(oldVal) && oldVal !=null){
        if(this.type=="list"){
          this.$emit('updateKeywordValue', newVal);
          this.changeKeywordValue=true
        }

        if(this.type=="detail"){
          this.$emit('updateKeywordValue', newVal);
          this.changeKeywordValue=true
        }
       // console.log(this.changeDateValue)
       // console.log("变化")
      }else{
        this.changeKeywordValue=false
      }
    },

    occupancy: {
      handler(newVal, oldVal) {

          //console.log('新的值:', JSON.stringify(newVal));
          //console.log('旧的值:', JSON.stringify(oldVal));
         // console.log("是否打开入住选项",this.openOccupancySelect)
          if(JSON.stringify(oldVal)!="{}"){

            if(JSON.stringify(newVal)!=JSON.stringify(oldVal)){
                this.changeOccupancy=true
                //this.searchHotelList()
                //console.log('变动')
              }else{
                this.changeOccupancy=false
                //console.log('未变动')

              }
          }

 

      },
      deep: true
    },

 
  },
  mounted() {

   
    //document.addEventListener('click', this.handleClickOutside);
  },
 
  created() {
    //console.log(4) 
   // console.log(this.propsKeyword)
    this.keywordValue=this.propsKeyword
   // console.log(this.keywordValue)
   // console.log(this.propsParams)
    if(this.propsParams){
      this.checkDates=[dayjs(this.propsParams.checkInDate), dayjs(this.propsParams.checkOutDate)]
      var updateOccupancy={
        rooms:this.propsParams.roomCount,
        adults:this.propsParams.adultCount,
        children:this.propsParams.childCount,
        childrenAges:[]
      }
      

      if(this.propsParams.childAgeList)  {
        updateOccupancy.childrenAges=this.propsParams.childAgeList.split(",").map(Number)

      }

      //console.log(updateOccupancy)
      this.occupancy=updateOccupancy
    }else{
      this.occupancy= {
          rooms: 1,
          adults: 2,
          children: 0,
          childrenAges: []
        }

    }
  },
  computed: {

  },
  methods: {

    searchHotelList() {

      
      //e.stopPropagation(); // 阻止冒泡
     // console.log(2);
     // console.log(this.keywordValue.name);
      if(!this.keywordValue.name){
        this.$refs.keywordSuggessionComponent.focusInput();
        return
      }
      this.changeKeywordValue=false  
      this.changeOccupancy=false
      this.changeDateValue=false
      const [start, end] = this.checkDates;
      
      const queryPara={            
                        checkInDate: start.format('YYYY-MM-DD'),
                        checkOutDate: end.format('YYYY-MM-DD'),
                        roomCount: this.occupancy.rooms,             
                        adultCount: this.occupancy.adults,
                        childCount: this.occupancy.children
                      }
      if(this.occupancy.children>0)
        queryPara.childAgeList =this.occupancy.childrenAges.join(',');
      
      //搜索城市
      if(this.keywordValue.type=="city"){
       //const updatedQueryPara = Object.assign({}, {cityId: this.keywordValue.id}, queryPara);
       queryPara.cityId = this.keywordValue.id;
        console.log(queryPara)
        router.push({
            name: 'HotelList',
            query: queryPara
        });
        this.$emit('updateHotelList', queryPara);
      }

      //搜索酒店
      if(this.keywordValue.type=="hotel"){
        queryPara.hotelId = this.keywordValue.id;
        router.push({
            name: 'HotelDetail',
            query: queryPara
        });
        this.$emit('updateHotelRate', queryPara);
      }
      //router.push('hotel/list')
     
   
    },


    disabledDate(current) {
        return current && current < dayjs().startOf('day');
    },
    onDatesChange(dates) {
   
      this.changeDateValue=true
      if (dates && dates.length === 2) {
        const [start, end] = dates;
        this.checkDates = [start, start.isSame(end)?start.add(1, 'day'):end] ;
        this.nights =start.isSame(end)?1:end.diff(start, 'days');
      } else {
        this.nights = 0;
      }
    },
    onCalendarChange(dates, dateStrings) {
      this.intermediateRange = { dates, dateStrings };
    },
    onDatesOpenChange(open){
      //console.log(open)
      if(open){
        this.openDatePicker=true
        this.openOccupancySelect=false
      }else{
        this.openDatePicker=false


        if (this.intermediateRange) {
        // 在这里处理选择完日期并关闭的动作
          this.openOccupancySelect=true
          this.$refs.occupancySelectComponent.toggleOccupancySelect() 
          this.intermediateRange = null;  // 清空中间变量
        }
        //console.log("是否打开入住选项",this.openOccupancySelect)
        if(this.changeKeywordValue&&!this.openOccupancySelect)
          this.searchHotelList()
      }
        

     
    },
    occupancyOpen(value){
      console.log("open",value)
      console.log("changeOccupancy",this.changeOccupancy)
      this.openOccupancySelect=value
      if((this.changeOccupancy || this.changeDateValue|| this.changeKeywordValue) && !value)  
        this.searchHotelList()
    },
    selectOccupancy(value){
   

      // 创建一个新的对象来替换原来的对象
      this.occupancy = { ...value };
      //this.openOccupancySelect=true


      //this.occupancy=value
      //console.log('Selected Occupancy:', value);

    },
    handleSelectKeyword(value) {
      this.keywordValue=value
      this.openDatePicker=true
     //console.log('Selected city:', value);
      // 处理选中的城市逻辑
    },

  },


};
</script>


<style scoped>

.search-box {
width: 100%;
display: flex;
align-items: center;
background-color: #fff;
border: 1px solid #ddd;
border-radius: 4px;
box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.search-box .search-box-item {
  padding: 15px;
margin-right: 20px;
}



.search-box label{display: block; margin-bottom: 10px;color: #8a8888;}
.search-box label span.checkin-date{margin-right: 175px}
.search-box .search-button {
background-color: #FF4D4F;
color: #fff;
padding: 10px 20px;
border-radius: 4px;
cursor: pointer;
}
.search-box .search-button:hover {
background-color: #FF7875;
}




.night{

  background: #eee;
  color: #333;
  text-align: center;
  border-radius: 12px;
  height: 24px;
  line-height: 24px;
  display:inline-block;
  width: 50px;
  margin-right: 15px
}

</style>



<template>
    <div>
      <h1>注册</h1>
      <form @submit.prevent="register">
        <div>
          <label>用户名:</label>
          <input type="text" v-model="userName" required>
        </div>
        <div>
          <label>电子邮件:</label>
          <input type="email" v-model="email" required>
        </div>
        <div>
          <label>密码:</label>
          <input type="password" v-model="password" required>
        </div>
        <button type="submit">注册</button>
      </form>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        userName: '',
        email: '',
        password: ''
      }
    },
    methods: {
      async register() {
        const response = await axios.post('/api/auth/register', {
          userName: this.userName,
          email: this.email,
          password: this.password
        });
        if (response.data.success) {
          alert('注册成功');
          this.$router.push('/login');
        } else {
          alert('注册失败');
        }
      }
    }
  }
  </script>
  
<template>

    <a-menu
      v-model:openKeys="openKeys"
      v-model:selectedKeys="selectedKeys"
      mode="inline"

      :inline-collapsed="collapsed"
      :items="items"
    ></a-menu>
 
</template>

<script>
import { h } from 'vue';
import {
  ShopFilled,
  IdcardFilled,
  AccountBookFilled,

  NotificationFilled,

} from '@ant-design/icons-vue';

export default {
  data() {
    return {
      collapsed: false,
      selectedKeys: ['order'],
      openKeys: ['order/list'],
      preOpenKeys: ['order/list'],
      items: [
        {
          key: 'order',
          icon: () => h(ShopFilled),
          label: '订单管理',
          title: '订单管理',
          children: [
            {
              key: 'order/list',
              label: '酒店订单',
              title: '酒店订单',
            },
            {
              key: 'order/tour/list',
              label: '旅游订单',
              title: '旅游订单',
            }
          ],
        },
        {
          key: 'billing',
          icon: () => h(AccountBookFilled),
          label: '账单管理',
          title: '账单管理',
          children: [
            {
              key: 'billing/list',
              label: '酒店账单明细',
              title: '酒店账单明细',
            },
            {
              key: 'billing/tour/list',
              label: '旅游账单明细',
              title: '旅游账单明细',
            }
          ],
        },

        {
          key: 'account',
          icon: () => h(IdcardFilled),
          label: '个人中心',
          title: '个人中心',
          children: [
            {
              key: 'account/info',
              label: '企业信息',
              title: '企业信息',
            },
            {
              key: 'account/list',
              label: '账号管理',
              title: '账号管理',
            }
          ],
        },
        {
          key: 'notice',
          icon: () => h(NotificationFilled),
          label: '系统通知',
          title: '系统通知',
        },
      ],
    };
  },
  watch: {
    openKeys(newVal, oldVal) {
      this.preOpenKeys = oldVal;
    },
  },
  methods: {
    toggleCollapsed() {
      this.collapsed = !this.collapsed;
      this.openKeys = this.collapsed ? [] : this.preOpenKeys;
    },
  },
};
</script>
